/* exported ErrorDisplaySettings */
/* global Require */
global.ErrorDisplaySettings = function (options) {

    var visible = options.visible || false;
    var caption = options.caption || '';
    var allowClose = angular.isFunction(options.onClose);
    var allowContinue = angular.isFunction(options.onContinue);

    return {
        get caption() { return caption; },
        set caption(value) { caption = value; },

        get visible() { return visible; },
        set visible(value) { visible = (value === true ? true : false); },

        allowClose: function () { return allowClose; },
        allowContinue: function () { return allowContinue; },

        open: function () {
            visible = true;
        },

        close: function () {
            if (options.onClose) {
                options.onClose();
            }
            visible = false;
        },
        continue: function () {
            if (options.onContinue) {
                options.onContinue();
            }
            visible = false;
        },
    };
};
