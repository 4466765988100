require('../node_modules/angular-material/angular-material.css');
require('../node_modules/angular-material-data-table/dist/md-data-table.css');
require('../node_modules/chart.js/dist/Chart');

require('lodash');
require('angular');
require('angular-material');
require('angular-animate');
require('angular-route');
require('angular-resource');
require('angular-cookies');
require('angular-messages');
require('angular-ui-router');
require('angular-material-data-table');
require('angular-fixed-table-header');


require('./pioneer/globalException/pioneer.Exceptions');

require('./thirdparty.module');