angular
    .module('pioneer.Exceptions')
    .directive('errorDisplay', [
        function () {
            return {
                restrict: 'E',
                template: require('./errorDisplay.html'),
                scope: {
                    settings: '='
                },
                transclude: true,
                link: function(scope) {
                    scope.visible = function() {
                        return scope.settings && scope.settings.visible;
                    };
                }
            };
        }]
    );