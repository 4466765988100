angular.module('pioneer.Exceptions').directive('globalException',
    [
        '$location',
        'globalExceptionService',
        '$timeout',
        function ($location, globalExceptionService, $timeout) {

            var hasCustomMesage = false;

            return {
                restrict: 'A',
                template: require('./globalexception.html'),
                link: function ($scope) {

                    function clearMessage() {
                        $scope.globalExceptionMessage = null;
                        globalExceptionService.clear();
                    }

                    $scope.globalExceptionDisplaySettings = new ErrorDisplaySettings({
                        visible: false,
                        onClose: function () {
                            clearMessage();
                        }
                    });

                    $scope.closeWindow = function () {
                        clearMessage();
                        $scope.globalExceptionDisplaySettings.visible = false;
                        $timeout(function() {
                            $location.url('/');
                        }, 0);
                    };

                    $scope.hasCustomMessage = function() {
                        return hasCustomMesage;
                    };

                    $scope.hasGenericMessage = function () {
                        return !hasCustomMesage;
                    };

                    $scope.$watch(function () {
                        return globalExceptionService.hasError();
                    }, function (newValue) {

                        var isStandardErrorMessage = newValue === true;

                        if (isStandardErrorMessage) {
                            $scope.globalExceptionMessage = globalExceptionService.getError();
                            $scope.globalExceptionDisplaySettings.visible = true;
                            hasCustomMesage = ($scope.globalExceptionMessage);
                            $scope.session = sessionStorage.getItem('telemetry-session');
                        } else {
                            $scope.globalExceptionDisplaySettings.visible = false;
                        }
                    });
                }
            };
        }
    ]);