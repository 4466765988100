(function () {
    'use strict';

    angular
        .module('pioneer.Exceptions')
        .config(Config);

    ExceptionHandlerFactory.$inject = [
        '$delegate',
        '$window',
        '$log',
        'globalExceptionService'
    ];

    function ExceptionHandlerFactory(
        $delegate,
        $window,
        $log,
        globalExceptionService
    ) {

        return processException;

        function processException(exception, cause) {

            var parsedException = parseException(exception);

            var loc = $window.location;

            if (exceptionOccurredLocally(loc)) {

                displayGlobalException(parsedException, cause);
                $log.error(exception);

            }
            else if (exceptionIsAProductionBug(loc)) {

                displayGlobalException(parsedException, cause);

            }
            else if (isJasmineUnitTest() || isChutzpahUnitTest(loc)) {

                $log.error(exception);
                throw exception;

            }

            $delegate(exception, cause);
        }

        function exceptionOccurredLocally(loc) {
            ///<summary>running on localhost and not telemetry</summary>
            return loc.hostname === 'localhost' && loc.port !== '9876';
        }

        function exceptionIsAProductionBug(loc) {
            ///<summary>not running on localhost and not telemetry</summary>
            return loc.hostname !== '' && loc.hostname !== 'localhost';
        }

        function isJasmineUnitTest() {
            /// <summary>Jasmine Unit Test running via PhantomJS in headless browser</summary>
            return window._phantom || window.callPhantom;
        }

        function isChutzpahUnitTest(loc) {
            /// <summary>Jasmine Unit Test running via Chutzpah in browser</summary>
            return loc.hostname === '' && loc.href !== '' && loc.href.substr(0, 4) === 'file';
        }

        function parseException(coreException) {
            if (coreException instanceof Error) {
                return new Exception(coreException.message,
                    $window.location.href,
                    sessionStorage.getItem('telemetry-session'),
                    null,
                    coreException.stack);
            }

            return new Exception(coreException.message,
                $window.location.href,
                sessionStorage.getItem('telemetry-session'),
                null,
                coreException.returnedData && coreException.returnedData.stackTrace ? coreException.returnedData.stackTrace : null);
        }

        function displayGlobalException(exception, cause) {
            if (exception.status !== 401) {
                globalExceptionService.displayErrorMessage();
            }
        }
    }

    Config.$inject = ['$provide'];

    function Config($provide) {
        $provide.decorator('$exceptionHandler', ExceptionHandlerFactory);
    }

    function Exception(message, url, sessionId, status,
        stack, // optional
        endpoint, // optional
        elapsedTime // optional
    ) {
        this.message = message;
        this.url = url;
        this.sessionId = sessionId;

        this.status = status || 418; // I'm a teapot (https://tools.ietf.org/html/rfc2324)
        this.stack = stack || (new Error()).stack;
        this.endpoint = endpoint || '';
        this.elapsedTime = elapsedTime || -1;

        this.isServerError = () => this.status !== 418;
        this.isProduction = () => this.url.indexOf('sales.') === 0;
        this.isChutzpah = () => this.url.indexOf('file') === 0;
        this.isJasmine = () => document.location.port === '9876';
        this.isUnauthorized = () => this.status === 401;
        this.isServerConnectionError = () => this.status === -1;

        this.toString = () => {
            let obj;
            if (this.isServerError()) {
                obj = _.pick(this, ['sessionId', 'message', 'status', 'url', 'endpoint', 'elapsedTime', 'stack']);
            } else {
                obj = _.pick(this, ['sessionId', 'message', 'url', 'stack']);
            }
            obj.stack = _.take(obj.stack.split('\n'), 5);
            return obj;
        };

    }

    Exception.prototype = Object.create(Error.prototype);

})();