angular.module('pioneer.Exceptions').service('globalExceptionService',
    [
        function () {
            var hasError = false;
            var errorMessage = null;
            var isEnabled = true;
            var connectivityProblem = false;

            var clear = function() {
                hasError = false;
                connectivityProblem = false;
                errorMessage = null;
            };

            return {
                disable: function() {
                    isEnabled = false;
                },
                hasError: function () {
                    return isEnabled ? hasError : false;
                },
                hasConnectivityProblem: function () {
                    return isEnabled ? connectivityProblem : false;
                },
                getError: function () {
                    return errorMessage;
                },
                displayErrorMessage: function (message) {
                    hasError = true;
                    errorMessage = message;
                },
                displayConnectivityMessage: function (message) {
                    hasError = true;
                    connectivityProblem = true;
                    errorMessage = message;
                },
                clear: function () {
                    clear();
                },
                overrideGlobalException: function (override) {
                    if (override) {
                        clear();
                    }
                }
            };
        }
    ]);